.profiler1kWord.hideProfilerStyles,
.profiler2kWord.hideProfilerStyles,
.profilerAwlWord.hideProfilerStyles,
.profilerA1Word.hideProfilerStyles,
.profilerA2Word.hideProfilerStyles,
.profilerB1Word.hideProfilerStyles,
.profilerB2Word.hideProfilerStyles,
.profilerC1Word.hideProfilerStyles,
.profilerC2Word.hideProfilerStyles {
  color: Black;
}

.profilerOffList {
  color: #575757 !important;
  font-weight: normal !important;
}

.profilerResults span.word:hover {
  color: Maroon;
}

.profilerAwlWord {
  color: #b200ff;
}

.awlStroop {
  background-color: #b200ff;
}

.profilerA1Word {
  color: #0099cc;
}

.a1Stroop {
  background-color: #0099cc;
}

.profilerA2Word {
  color: #00bb00;
  font-weight: bold;
}

.a2Stroop {
  background: #00bb00;
}

.profilerB1Word {
  color: #ff9900;
  font-weight: bold;
}

.b1Stroop {
  background: #ff9900;
}

.profilerB2Word {
  color: #b30000;
  font-weight: bold;
}

.b2Stroop {
  background: #cd5c5c;
}

.profilerC1Word {
  color: #d733ff;
  font-weight: bold;
}

.c1Stroop {
  background: #d733ff;
}

.profilerC2Word {
  color: #db7093;
  font-weight: bold;
}

.c2Stroop {
  background: #d4318c;
}

@media (min-width: 200px) {
  #resultsParagraph {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  #resultsParagraph {
    margin-top: 79px;
  }
}

@media (min-width: 1200px) {
  #resultsParagraph {
    margin-top: 50px;
  }
}
